import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import { OptimizedImage } from '../../components/common/OptimizedImage';

export default () => (
  <MainLayout withTitle>
    <div className="faq-wrapper">
      <div className="faq-content-wrapper">
        <div className="hdr text-left">
          <h2 className="mgn-bot-30">Update Delivery Address</h2>
          <p className="qnA-text">
            If you need to update your address for both short or long term you
            can do this through your Dashboard.
          </p>
          <p className="mgn-top-30 qnA-text">
            1. Log in to your account and view your Dashboard
            <br />
            2. Click on "My Account" <br />
            3. Click on "Add new Delivery Address" <br />
            4. Make your new address your primary address.
          </p>
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="login.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="my_acc_step2.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="add_address.png" />
        </div>
        <div className="mgn-bot-50">
          <OptimizedImage fileName="add_address_step4.png" />
        </div>
        <p className="qnA-text mgn-top-50">
          If you didn't find the answer you were looking for please contact us,
          we would love to help! <br />
          Email: info@feedmyfurbaby.co.nz <br />
          Phone: 09 88 MYPET (09 886 9738) <br />
          Facebook: @myfurbabynz <br />
          Or contact us using our webchat.
        </p>
      </div>
    </div>
  </MainLayout>
);
